// Dans votre fichier firebase.js, ajoutez l'importation de Firebase Auth
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider } from "firebase/auth";



// Configuration de Firebase pour votre application web
const firebaseConfig = {
  apiKey: "AIzaSyAqOhrzXx5mPTgUIhaeGRaYPr2NGVe10-E",
  authDomain: "pawpaw-app.firebaseapp.com",
  projectId: "pawpaw-app",
  storageBucket: "pawpaw-app.appspot.com",
  messagingSenderId: "288205219706",
  appId: "1:288205219706:web:4b96b9dfe79bd847b798c3",
  measurementId: "G-ZXVEY4S3K8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);  // Initialisation de Firebase Auth

if (typeof auth.settings !== 'undefined') {
  auth.settings.appVerificationDisabledForTesting = true;
}

export {
  db,
  auth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider
};
<template>
  <div class="register-user">
    <div id="recaptcha-container"></div>
    <h1>Inscription</h1>
    <form @submit.prevent="registerWithEmail">
      <input v-model="newUser.email" type="email" placeholder="Email" required>
      <input v-model="newUser.password" type="password" placeholder="Mot de passe" required>
      <button type="submit">S'inscrire</button>
    </form>
    
    <button @click="signInWithGoogle">S'inscrire avec Google</button>
    
    <!-- Formulaire d'inscription par SMS -->
    <form @submit.prevent="signInWithPhoneNumber">
      <input v-model="phone" type="text" placeholder="Numéro de téléphone" required>
      <button type="submit">Envoyer le code</button>
    </form>
    
    <!-- Formulaire de vérification du code -->
    <form v-if="verificationId" @submit.prevent="verifyCode">
      <input v-model="code" type="text" placeholder="Code de vérification" required>
      <button type="submit">Vérifier le code</button>
    </form>
  </div>
</template>

<script>
import { auth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signInWithPhoneNumber, PhoneAuthProvider, RecaptchaVerifier } from '@/firebase';

export default {
  data() {
    return {
      newUser: {
        email: '',
        password: ''
      },
      phone: '+32479578949',
      code: '',
      verificationId: null
    };
  },
  methods: {
    registerWithEmail() {
      createUserWithEmailAndPassword(auth, this.newUser.email, this.newUser.password)
        .then((userCredential) => {
          console.log("Utilisateur créé avec succès : ", userCredential.user);
          alert('Inscription réussie!');
          this.newUser.email = '';
          this.newUser.password = '';
        })
        .catch((error) => {
          console.error("Erreur lors de l'inscription : ", error);
          alert('Erreur d\'inscription : ' + error.message);
        });
    },
    signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log("Utilisateur connecté via Google : ", result.user);
          alert('Connexion réussie avec Google!');
        })
        .catch((error) => {
          console.error("Erreur de connexion Google : ", error);
          alert('Erreur de connexion avec Google : ' + error.message);
        });
    },
    signInWithPhoneNumber() {
      const appVerifier = new RecaptchaVerifier('recaptcha-container', { 'size': 'invisible' }, auth);
      signInWithPhoneNumber(auth, this.phone, appVerifier)
        .then((confirmationResult) => {
          this.verificationId = confirmationResult.verificationId;
          alert('Code de vérification envoyé!');
        })
        .catch((error) => {
          console.error("Erreur lors de l'envoi du code : ", error);
          alert('Erreur lors de l\'envoi du code : ' + error.message);
        });
    },
    verifyCode() {
      const credential = PhoneAuthProvider.credential(this.verificationId, this.code);
      auth.signInWithCredential(credential)
        .then((userCredential) => {
          console.log("Téléphone vérifié et utilisateur connecté : ", userCredential.user);
          alert('Téléphone vérifié avec succès!');
        })
        .catch((error) => {
          console.error("Erreur lors de la vérification du code : ", error);
          alert('Erreur lors de la vérification : ' + error.message);
        });
    }
  }
};
</script>

<style scoped>
input, button {
  margin: 10px;
  padding: 8px;
  display: block;
}
</style>
